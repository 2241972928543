        <template>
  <b-card>
    <b-row
          >
                                    <b-col
                                                                                      sm="3"
                                        >
                            <select-input
  id="lev"
  name="lev"
    :value.sync="xparam1"
        :options="[]"
      :getOptionLabel="option =&gt; option.text"
      :reduce="val =&gt; val.value"
            label="Уровень"
  api="https://api.prohealthfactor.ru/api/v1/json/lkb/service/data/list/lev"
  />            </b-col>
                                        <b-col
                                                                                      sm="9"
                                        >
              <div
                                class="d-flex justify-content-end pt-2"
                              >
                                  <div
                                        >
                                            <b-button
      v-b-modal.sp-modal-form
          variant="outline-primary"
    >Добавить партнера</b-button>                  </div>
                                  <div
                                        >
                                            <download-button
  href="https://api.prohealthfactor.ru/api/v1/json/lkb/buyer/lp/export?xparam2=0"
                    label="Excel"
  format="xlsx"
    variant="outline-primary"
        classes="ml-50"
  />                  </div>
                                  <div
                                        >
                                            <refresh-button
  label="Обновить"
  :refresh.sync="refresh"
    variant="outline-primary"
        classes="ml-50"
  />                  </div>
                              </div>
            </b-col>
                                        <b-col
                                                                                      sm="12"
                                        >
                            <basic-table
  :columns="[{&quot;key&quot;:&quot;bu_date&quot;,&quot;sortable&quot;:true,&quot;label&quot;:&quot;\u0414\u0430\u0442\u0430&quot;,&quot;type&quot;:&quot;text&quot;,&quot;thClass&quot;:&quot;text-center&quot;,&quot;tdClass&quot;:&quot;text-center&quot;},{&quot;key&quot;:&quot;buyerC&quot;,&quot;sortable&quot;:true,&quot;label&quot;:&quot;\u041d\u043e\u043c\u0435\u0440&quot;,&quot;type&quot;:&quot;text&quot;,&quot;thClass&quot;:&quot;text-center&quot;,&quot;tdClass&quot;:&quot;text-right&quot;},{&quot;key&quot;:&quot;FIO&quot;,&quot;sortable&quot;:true,&quot;label&quot;:&quot;\u0424\u0418\u041e&quot;,&quot;type&quot;:&quot;text&quot;,&quot;thClass&quot;:&quot;text-center&quot;},{&quot;key&quot;:&quot;bu_GO&quot;,&quot;sortable&quot;:true,&quot;label&quot;:&quot;\u0421\u0435\u0442\u044c&quot;,&quot;type&quot;:&quot;float&quot;,&quot;thClass&quot;:&quot;text-center&quot;,&quot;tdClass&quot;:&quot;text-right&quot;},{&quot;key&quot;:&quot;bu_LO&quot;,&quot;sortable&quot;:true,&quot;label&quot;:&quot;\u041b\u041f&quot;,&quot;type&quot;:&quot;float&quot;,&quot;thClass&quot;:&quot;text-center&quot;,&quot;tdClass&quot;:&quot;text-right&quot;},{&quot;key&quot;:&quot;branch&quot;,&quot;sortable&quot;:true,&quot;label&quot;:&quot;\u0412\u0435\u0442\u043a\u0430&quot;,&quot;type&quot;:&quot;text&quot;,&quot;thClass&quot;:&quot;text-center&quot;},{&quot;key&quot;:&quot;town_name&quot;,&quot;sortable&quot;:true,&quot;label&quot;:&quot;\u0413\u043e\u0440\u043e\u0434&quot;,&quot;type&quot;:&quot;text&quot;,&quot;thClass&quot;:&quot;text-center&quot;},{&quot;key&quot;:&quot;Status&quot;,&quot;sortable&quot;:true,&quot;label&quot;:&quot;\u0421\u0442\u0430\u0442\u0443\u0441&quot;,&quot;type&quot;:&quot;text&quot;},{&quot;key&quot;:&quot;Descr&quot;,&quot;sortable&quot;:false,&quot;label&quot;:&quot;\u041a\u043e\u043d\u0442\u0430\u043a\u0442\u044b&quot;,&quot;type&quot;:&quot;text&quot;,&quot;thClass&quot;:&quot;text-center&quot;}]"
          :refresh="refresh"
        :xparam1="xparam1"
                                api="https://api.prohealthfactor.ru/api/v1/json/lkb/buyer/lp/grid"
          />            </b-col>
                                        <b-col
                                                                                                >
                            <sp-modal-form
            sendApi="https://api.prohealthfactor.ru/api/v1/json/lkb/buyer/contract/add"
            method="post"
            getApi=""
            id="sp-modal-form"
            title="Добавить партнера"
    />
            </b-col>
                            </b-row>
  </b-card>
</template>
<script>
/* eslint-disable */
// prettier-ignore
import {
  BCard,
  BRow,
  BCol,
} from 'bootstrap-vue'

import { ref } from '@vue/composition-api'

                                      import SelectInput from '@core/components/input/select-input/SelectInput.vue'
                                                                            import {
              BButton,
            } from 'bootstrap-vue'
                                                                        import DownloadButton from '@core/components/button/DownloadButton.vue'
                                                                        import RefreshButton from '@core/components/button/RefreshButton.vue'
                                                                          import BasicTable from '@core/components/table/basic-table/BasicTable.vue'
                                                              import SpModalForm from '@core/components/sp/sp-modal-form/SpModalForm.vue'
                            
export default {
  components: {
    BCard,
    BRow,
    BCol,

                                                          SelectInput,
                                                                                              BButton,
                                                                            DownloadButton,
                                                                            RefreshButton,
                                                                                            BasicTable,
                                                                              SpModalForm,
                                          },
  setup() {
                const xparam1 = ref('1')
            const refresh = ref(false)
          
          return {
            xparam1,
            refresh,
            }
      },
}
</script>
    